import { mapActions } from 'vuex'

export default {
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    itemSync: {},
    model: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    
    headers: [
        { text: 'Código', value: 'code' },
        { text: 'Nome', value: 'name' },
        { text: 'Descrição', value: 'description', sortable: false},
        { text: 'Padrão', value: 'default', align: 'center', sortable: false, type: 'boolean'},
        { text: 'Status', value: 'active', align: 'center', sortable: false},
        { text: '', value: 'actions', align: 'center', sortable: false},
    ]
    }),

    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
      
    },
    methods: {
      ...mapActions('worktime', ['ActionIndexWorkTimeCategories', 'ActionDeleteWorkTimeCategory']),
        
    getData(showProgress = true){
      
        this.error = false;
        this.message = ''; 
        this.loadingProgress = showProgress;
        this.loading = true;
        var params = this.params;
        this.showForm = false;
       

        this.ActionIndexWorkTimeCategories(params)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    deleteItem()
    {
        this.deleting = true;
        this.message = '';

        this.ActionDeleteWorkTimeCategory({ id: this.itemDelete.id })
            .then((res) => {
                this.message = res.message;
                this.show = false;
                this.getData();
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                this.deleting = false;
            });
    },

    
  },
  created(){
      
  }

}
